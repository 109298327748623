<template>

  <div>
    <b-card>

      <h2 class="mb-2">
        Search Filter
      </h2>

      <!-- Table Top -->
      <b-row>

        <b-col
          cols="12"
          md="3"
          class="w-100"
        >
          <v-select
            v-model="filterDataUserType"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterUserTypeOptions"
            :clearable="false"
            :placeholder="'Select Type'"
          />
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="w-100"
        >
          <v-select
            v-if="filterProgramOptions.length > 0"
            v-model="filterDataProgram"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterProgramOptions"
            :reduce="program => program.id"
            :clearable="false"
            :placeholder="'Select Program'"
          />
          <v-select
            v-else
            :placeholder="'Select Program'"
          />
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="w-100"
        >
          <v-select
            v-model="filterDataStatus"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterStatusOptions"
            :clearable="false"
            :placeholder="'Select Status'"
          />
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <div class="d-flex align-items-center">
            <b-button
              class="ml-1"
              variant="primary"
              @click="clearFilters"
            >
              <span class="mr-25 align-middle">Reset filters</span>
            </b-button>
          </div>
        </b-col>

      </b-row>

    </b-card>
    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="table-header m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-checkbox
              v-model="elementsPerPageCheckbox"
              class="mr-1 bulk-checkbox"
              @change="markElementsOnPage"
            />

            <label class="mr-1 mb-0">Show</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :value="perPage"
              :clearable="false"
              :reduce="option => option.value"
              class="per-page-selector d-inline-block ml-50 mr-1"
              @input="updatePerPage"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <label class="mr-1 mb-0">Search</label>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                class="w-25"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="fetchTaskList"
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Loader -->
        <template #table-busy>
          <TableSpinner />
        </template>

        <template #cell(selected)="data">
          <b-form-checkbox
            v-model="markedElements"
            :value="data.item.id"
            inline
            @change="handleElementsPerPageCheckbox"
          />
        </template>

        <!-- Column: user_name -->
        <template #cell(task_name)="data">
          <b-link
            v-if="data.item.status === 'Completed'"
            :to="{ name: 'admin-task-edit', params: { id: data.item.id } }"
          >{{ data.item.task_name }}</b-link>
          <span v-else>{{ data.item.task_name }}</span>
        </template>

        <!-- Column: user_name -->
        <template #cell(user_name)="data">
          <b-link
            :to="{ name: 'admin-application-edit', params: { id: data.item.application_id, type: getApplicationUserType(data.item) } }"
          >{{ data.item.user_name }}</b-link>
        </template>

        <!-- Column: status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: due_date -->
        <template #cell(date)="data">
          <span>{{ data.item.date | shortDateLocal }}</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="mx-auto p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="$can('update', permissionSubjects.UserTasks)"
              v-b-modal.edit-application-task
              @click="editableTaskId = data.item.id"
            >
              <span>Edit Task</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$can('delete', permissionSubjects.UserTasks) && data.item.status !== 'Manual Deleted'"
              @click="showDeleteTaskConfirmation(data.item)"
            >
              <span>Delete</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="totalItems && perPage !== 'all'"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>

        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center mt-2"
          >
            <b-dropdown
              id="dropdown-buttons"
              text="Bulk Actions"
              variant="outline-danger"
            >
              <b-dropdown-item
                v-b-modal.bulk-add-to-group-modal
                :disabled="markedElements.length < 1"
              >
                Bulk Add To Group
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canSendBulkCommunication"
                v-b-modal.bulk-communication-modal
                :disabled="markedElements.length < 1"
              >
                Bulk Communication
              </b-dropdown-item>
              <b-dropdown-item
                v-b-modal.bulk-adding-charge-modal
                :disabled="markedElements.length < 1"
              >
                Bulk Charge
              </b-dropdown-item>
              <b-dropdown-item
                v-b-modal.bulk-generate-letter-modal
                :disabled="markedElements.length < 1"
              >
                Bulk Generate Letter
              </b-dropdown-item>
              <b-dropdown-item
                v-b-modal.bulk-tag-assign-modal
                :disabled="markedElements.length < 1"
              >
                Bulk Tag Assign
              </b-dropdown-item>
            </b-dropdown>
          </b-col>

        </b-row>
      </div>

    </b-card>

    <application-task-edit-modal
      :application-task-id="editableTaskId"
      @updated="refetchData"
    />

    <bulk-communicate-modal
      v-if="markedElements.length"
      :marked-elements="markedElements"
      :total-elements-count="markedElements.length"
      :filter-name="'application_task_id'"
      @successfullySend="bulkCommunicateSuccess"
    />

    <bulk-add-to-group-modal
      v-if="markedElements.length"
      :marked-elements="markedElements"
      :filter-name="'application_task_id'"
      @successfullySend="bulkAddToGroupSuccess"
    />

    <bulk-adding-charge-modal
      v-if="markedElements.length"
      :marked-elements="markedElements"
      :filter-name="'application_task_id'"
      @success="bulkCreateChargeSuccess"
    />

    <bulk-generate-letter-modal
      v-if="markedElements.length"
      :marked-elements="markedElements"
      :filter-name="'application_task_id'"
      @success="bulkGenerateLetterSuccess"
    />

    <bulk-tag-assign-modal
      v-if="markedElements.length"
      :marked-elements="markedElements"
      :filter-name="'application_task_id'"
      :program-id="programId"
      @success="bulkTagAssignSuccess"
    />

    <confirmation-modal
      v-if="deletableTask"
      :toggle-modal="confirmationModalShow"
      :title="'Are you sure'"
      :message="`Are you sure you want to delete this application task for application(${deletableTask.user_name})? All related field results and PPA for this task will be lost`"
      :is-loading="isTaskDeleteLoading"
      @close-modal="confirmationModalShow = !confirmationModalShow"
      @confirm="handleDeleteTask"
    />
  </div>

</template>

<script>
import { filters } from '@core/mixins/filters'
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination, BBadge, BLink, BDropdown, BDropdownItem, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'

import vSelect from 'vue-select'
import store from '@/store'
import storeModule from '@/views/admin/tasks/taskStoreModule'
import useApplicationTaskList from '@/views/admin/tasks/application-task-list/useApplicationTaskList'
import ApplicationTaskEditModal from '@/views/admin/tasks/application-task-list/ApplicationTaskEditModal.vue'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import BulkCommunicateModal from '@/views/components/bulk-communicate-modal/BulkCommunicateModal.vue'
import BulkAddToGroupModal from '@/views/components/bulk-add-to-group/BulkAddToGroupModal.vue'
import BulkAddingChargeModal from '@/views/components/adding-charge-modal/BulkAddingChargeModal.vue'
import BulkGenerateLetterModal from '@/views/components/bulk-generate-letter/BulkGenerateLetterModal.vue'
import BulkTagAssignModal from '@/views/components/bulk-tag-assign/BulkTagAssignModal.vue'
import { permissionSubjects } from '@/libs/acl/constants'
import { DEFAULT_PER_PAGE } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'
import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'

export default {
  name: 'ApplicationTaskList',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BBadge,
    BLink,
    BDropdown,
    BDropdownItem,
    BButton,
    BFormCheckbox,
    vSelect,

    ApplicationTaskEditModal,
    TableSpinner,

    BulkCommunicateModal,
    BulkAddToGroupModal,
    BulkAddingChargeModal,
    BulkGenerateLetterModal,
    BulkTagAssignModal,
    ConfirmationModal,
  },
  mixins: [filters],
  data() {
    return {
      editableTaskId: null,
      filterStatusOptions: ['To Do', 'Completed', 'Overdue'],
      filterUserTypeOptions: ['Student', 'Staff'],
      filterProgramOptions: [],
      markedElements: [],
      elementsPerPageCheckbox: false,
      isLoading: false,
      permissionSubjects,
    }
  },
  setup(props, { root }) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-application-tasks'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchTaskList,
      programId,
      tableColumns,
      perPage,
      perPageOptions,
      currentPage,
      totalItems,
      searchQuery,
      filterDataUserType,
      filterDataProgram,
      filterDataStatus,
      sortBy,
      dataMeta,
      isSortDirDesc,
      refInvoiceListTable,
      taskId,
      clearFilters,
      refetchData,

      resolveStatusVariant,
      fetchPrograms,
      updateApplicationTask,
    } = useApplicationTaskList(root)

    return {
      fetchTaskList,
      programId,
      tableColumns,
      perPage,
      perPageOptions,
      currentPage,
      totalItems,
      searchQuery,
      filterDataUserType,
      filterDataProgram,
      filterDataStatus,
      sortBy,
      dataMeta,
      isSortDirDesc,
      refInvoiceListTable,
      taskId,
      clearFilters,
      refetchData,

      resolveStatusVariant,
      fetchPrograms,
      updateApplicationTask,
    }
  },
  computed: {
    canSendBulkEmailCommunication() {
      return this.$can('send', permissionSubjects.BulkEmailCommunication)
    },
    canSendBulkSmsCommunication() {
      return this.$can('send', permissionSubjects.BulkSMSCommunication)
    },
    canSendBulkCommunication() {
      return this.canSendBulkEmailCommunication || this.canSendBulkSmsCommunication
    },
    deletableTask: {
      get() {
        return store.getters['app-application-tasks/getDeletableTask']
      },
      set(val) {
        store.commit('app-application-tasks/SET_DELETABLE_TASK', val)
      },
    },
    confirmationModalShow: {
      get() {
        return store.getters['app-application-tasks/getConfirmationModalShow']
      },
      set(val) {
        store.commit('app-application-tasks/SET_CONFIRMATION_MODAL_SHOW', val)
      },
    },
    isTaskDeleteLoading: {
      get() {
        return store.getters['app-application-tasks/getIsTaskDeleteLoading']
      },
      set(val) {
        store.commit('app-application-tasks/SET_IS_TASK_DELETE_LOADING', val)
      },
    },
  },
  watch: {
    dataMeta() {
      this.handleElementsPerPageCheckbox()
    },
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
      this.refetchData()
    },
  },
  async created() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }

    await this.prepareOptions()
  },
  methods: {
    async prepareOptions() {
      this.filterProgramOptions = await this.fetchPrograms({ taskId: this.taskId })
        .then(response => response.reduce((arr, item) => {
          arr.push({ id: item.id, label: item.name })
          return arr
        }, []))
    },
    getApplicationUserType(info) {
      return info.camper_id !== null ? this.camperStudent(1) : 'Staff'
    },
    updatePerPage(val) {
      localStorage.setItem('applicationTasksPerPage', val)
      this.perPage = val
    },
    setFilterParams() {
      const query = { ...this.$route.query }
      const defaultPerPage = localStorage.getItem('applicationTasksPerPage')
      this.currentPage = Number(query.currentPage) || 1
      this.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
      this.searchQuery = query.searchQuery || ''
      this.taskId = this.$route.params.taskId
      this.filterDataUserType = query.filterDataUserType || ''
      this.filterDataProgram = query.filterDataProgram || ''
      this.filterDataStatus = query.filterDataStatus || ''
    },
    markElementsOnPage(val) {
      if (val) {
        this.refInvoiceListTable.localItems.forEach(task => {
          const index = this.markedElements.indexOf(task.id)
          if (index < 0) {
            this.markedElements.push(task.id)
          }
        })
      } else {
        this.refInvoiceListTable.localItems.forEach(task => {
          const index = this.markedElements.indexOf(task.id)
          if (index >= 0) {
            this.markedElements.splice(index, 1)
          }
        })
      }
    },
    handleElementsPerPageCheckbox() {
      let counter = 0
      this.refInvoiceListTable.localItems.forEach(task => {
        if (this.markedElements.indexOf(task.id) !== -1) {
          counter += 1
        }
      })

      this.elementsPerPageCheckbox = !!(this.refInvoiceListTable.localItems && counter === this.refInvoiceListTable.localItems.length)
    },
    async bulkCommunicateSuccess() {
      this.$bvModal.hide('bulk-communication-modal')
      await this.clearData()
    },
    async bulkAddToGroupSuccess() {
      this.$bvModal.hide('bulk-add-to-group-modal')
      await this.clearData()
    },
    async bulkCreateChargeSuccess() {
      this.$bvModal.hide('bulk-adding-charge-modal')
      await this.clearData()
    },
    async bulkGenerateLetterSuccess() {
      this.$bvModal.hide('bulk-generate-letter-modal')
      await this.clearData()
    },
    async bulkTagAssignSuccess() {
      this.$bvModal.hide('bulk-tag-assign-modal')
      await this.clearData()
    },
    async clearData() {
      this.markedElements = []
      this.elementsPerPageCheckbox = false
      this.refetchData()
    },
    async deleteApplicationTask(applicationTaskId) {
      this.isTaskDeleteLoading = true
      const queryParams = {
        application_task_id: applicationTaskId,
        status: 'Manual Deleted',
      }

      await this.updateApplicationTask(queryParams)
        .finally(() => {
          this.deletableTask = null
          this.isTaskDeleteLoading = false
          this.confirmationModalShow = false
          this.$bvModal.hide('edit-application-task')
          this.refetchData()
        })
    },
    showDeleteTaskConfirmation(task) {
      this.deletableTask = task
      this.$nextTick(() => {
        this.confirmationModalShow = true
      })
    },
    handleDeleteTask() {
      if (this.deletableTask) {
        this.deleteApplicationTask(this.deletableTask.id)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>
